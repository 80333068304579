import React, { useEffect, useState, useRef } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import {
  bit,
  blessingWriteTitleImg,
  creditCards,
  homeBg,
  homeBgNoPeople,
  moneyPresentImg,
  orangeBgImage,
  orangeBgImageNoPeople,
  paybox,
  paypalLong,
  prevBtnImage,
  shippingBg,
  shippingBgNoPeople,
  shippingDecor,
  shippingTitleIcon,
} from "../../Assets/images";
import { setError, validateTextInput } from "../../Libs/forms";
import axios from "axios";
import { API_DOMAIN } from "../../Libs/config";
import { alertClasses } from "@mui/material";

function MyBankCreateOrder({
  sendChoosedPresentStep,
  setSendChoosedPresentStep,
  progress,
  setProgress,
  stepNumber,
  setStepNumber,
  setMainBgImage,
  setOrange,
  setGreenMobile,
  setForm,
  setChoosed,
  resultObject,
  setResultObject,
  choosedPresent,
  wishListInfo,
  isOrange,
  languageToggle,
  language,
  resetMyBankOrderFlow,
  updateUserBank,
  currUserObject
}) {

  useEffect(()=>{
console.log("PPPPPPPPPPPPP", choosedPresent)
  },[])
  const [payment, setPayment] = useState({});
  const [showErrorShipping, setShowErrorShipping] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [myBank, setMyBank] = useState(0);
  const [isBit, setIsBit] = useState(false)

  const navigate = useNavigate();
  const iframeRef = useRef(null);
  const [orderId, setOrderId] = useState();
  let currentUser = localStorage.getItem("currUserObject");
  if (currentUser) {
    currentUser = JSON.parse(currentUser);
    console.log("$$$$$$$$$$$$$$$$$$$$", currentUser)
  }
  const location = useLocation();
  const [blessingWriteStepInfo, setBlessingWriteStepInfo] = useState({
    blessingTitle: "",
    blessingText: "",
  });
  const [additionalMoneyNeeded, setAdditionalMoneyNeeded] = useState(0);
  const getUserData = async () => {
    try {
      const currUserObject = JSON.parse(localStorage.getItem("currUserObject"));

      // Check if currUserObject is not null
      if (currUserObject) {
        const { user } = currUserObject;

        // Check if user is not null
        if (user) {
          const { id } = user;

          const response = await axios.get(
            `${API_DOMAIN}/api/Authenticate/getUserData?userId=${id}`
          );

          if (response.status === 200) {
            const {
              data: {
                user: { myBank },
              },
            } = response;

            //setUserData(response.data);
            setMyBank(myBank ? myBank : 0);
          } else {
            console.error("Unexpected response status:", response.status);
           // alert("Something went wrong");
          }
        } else {
          console.error("Invalid user object: user is null");
          // Handle the case where user is null
        }
      } else {
        console.error("currUserObject is null");
        // Handle the case where currUserObject is null
      }
    } catch (error) {
      console.error(error);
     // alert("Something went wrong");
    }
  };
useEffect(()=>{
    const giftPrice = choosedPresent.price;
    
    if (myBank >= giftPrice) {
      setAdditionalMoneyNeeded(0);
    } else {
      setAdditionalMoneyNeeded(giftPrice - myBank);
    }
 
},[myBank])


  useEffect(()=>{
    getUserData();
  },[])
  const [shippingStepInfo, setShippingStepInfo] = useState({});
  const [moneyPresentStepInfo, setMoneyPresentStepInfo] = useState({});
  useEffect(() => {
    if (window.innerWidth < 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, []);
  useEffect(() => {
    // Add event listener to listen for messages from the iframe
    window.addEventListener('message', handleMessage);

    // Clean up the event listener when component unmounts
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  const handleMessage = (event) => {
    // Check if the message is from the iframe
    try {
      const data = JSON.parse(event.data);
      if (data.message === 'thank_you_page') {
          // Redirect the parent window to the desired URL
          window.location.href = data.iframeUrl;
      }
  } catch (e) {
      // Handle any parsing errors here
      console.error('Error parsing JSON data:', e);
  }
      
}
  
  const goBackToChoosePresent = (e) => {
    e.preventDefault();
    setChoosed(false);
    setSendChoosedPresentStep({
      blessingWrite: false,
      shipping: false,
      moneyPresent: false,
      payment: false,
    });
    // navigate('/my-bank');
    // window.history.go(-1);
    console.log('goBackToChoosePresent')
    navigate(-1);
    setTimeout(() => {
    console.log('goBackToChoosePresent')
      window.scrollTo(0, 0);
    }, 0);

  };

  const goToBlessingWriteStep = () => {
    setSendChoosedPresentStep({
      blessingWrite: true,
      shipping: false,
      moneyPresent: false,
      payment: false,
    });
    setResultObject((prevObj) => {
      const newObj = prevObj;
      delete newObj["presentBox"];
      delete newObj["collectionFromBusiness"];
      delete newObj["deliveryReceiver"];
      delete newObj["courierReceiver"];
      delete newObj["sendByRegisteredMail"];
      delete newObj["sendWithCourier"];
      delete newObj["notificationsAgree"];
      delete newObj["regulationsConfirm"];

      return newObj;
    });
    setStepNumber(3);
    setMainBgImage({
      main: homeBg,
      noPeople: homeBgNoPeople,
    });
    setOrange(true);
    setForm(true);
    setGreenMobile(true);
    setProgress(5);
    // navigate('write-blessing')
    window.scrollTo(0, 0);
  };

  const submitBlessingWriteForm = (e) => {
    e.preventDefault();

    const form = e.target;
    const blessingNameInput = form["blessing-name"];
    const blessingMessageInput = form["blessing-message"];

    const isBlessingName = validateTextInput(blessingNameInput, 1);
    const isBlessingMessage = validateTextInput(blessingMessageInput, 1);

    const isFormValid = isBlessingName && isBlessingMessage;
    if (isFormValid) {
      setResultObject((prevObject) => {
        const newObject = {
          ...prevObject,
          blessingTitle: blessingNameInput.value,
          blessingText: blessingMessageInput.value,
        };
        return newObject;
      });
      setBlessingWriteStepInfo({
        blessingTitle: blessingNameInput.value,
        blessingText: blessingMessageInput.value,
      });
      goToShippingStep();
    }
    window.scrollTo(0, 0);

  };

  const goToShippingStep = () => {
    setSendChoosedPresentStep({
      blessingWrite: false,
      shipping: true,
      moneyPresent: false,
      payment: false,
    });
    setStepNumber(3);
    setMainBgImage({
      main: shippingBg,
      noPeople: shippingBgNoPeople,
    });
    setOrange(true);
    setForm(true);
    setGreenMobile(true);
    setProgress(30);
    window.scrollTo(0, 0);

  };

  const isRegulationChecked = (e) => {
    const form = e.target;
    const regulationsConfirm = form["regulations-confirm"];
    return regulationsConfirm?.checked;

  }
useEffect(()=>{
  console.log("&&&&&&&&&&&&&&&&&&&&&&&", choosedPresent)

},[choosedPresent])
  const submitShippingForm = (e) => {
    e.preventDefault();

    const form = e.target;
    const presentBox = form["present-box"];
    const deliveryReceiver = form["shipping-option"];
   // const collectionFromBusiness = form["collection-from-business"];
  //  const deliveryReceiver = form["delivery-receiver"];
  // const courierReceiver = form["courier-receiver"];
   //  const sendByRegisteredMail = form["send-by-registered-mail"];
    //const sendWithCourier = form["send-with-courier"];
    const notificationsAgree = form["notifications-agree"];
    const regulationsConfirm = form["regulations-confirm"];
    const isRegulatrionsConfirm = regulationsConfirm?.checked;

   // const isDeliveryReceiver = validateTextInput(deliveryReceiver, 1);
    if(!deliveryReceiver.value)
     setShowErrorShipping(true)
    else
     setShowErrorShipping(false)

    console.log('isRegulatrionsConfirm', isRegulatrionsConfirm);
    console.log("!!!!!!!!!", deliveryReceiver.value)
    
    if (isRegulatrionsConfirm && deliveryReceiver.value) {
      setResultObject((prevObject) => {
        const newObject = {
          ...prevObject,
          presentBox: presentBox?.checked,
      //  collectionFromBusiness: collectionFromBusiness.checked,
        //  deliveryReceiver: deliveryReceiver.value,
         // courierReceiver: courierReceiver.value,
        //  sendByRegisteredMail: sendByRegisteredMail.checked,
         // sendWithCourier: sendWithCourier.checked,
         deliveryReceiver: deliveryReceiver.value,
          notificationsAgree: notificationsAgree?.checked,
          regulationsConfirm: regulationsConfirm?.checked,
        };
        console.log('submitShippingForm', newObject)
        return newObject;
      });

      setShippingStepInfo({
        presentBox: presentBox?.checked,
        deliveryReceiver: deliveryReceiver?.value,
     //   collectionFromBusiness: collectionFromBusiness.checked,
       // deliveryReceiver: deliveryReceiver.value,
      //  courierReceiver: courierReceiver.value,
      //  sendByRegisteredMail: sendByRegisteredMail.checked,
       // sendWithCourier: sendWithCourier.checked,
        notificationsAgree: notificationsAgree?.checked,
        regulationsConfirm: regulationsConfirm?.checked,
      });



      goToMoneyPresentStep();
      navigate('money')
      if(additionalMoneyNeeded){
      //  alert(`צריך להוסיף ${additionalMoneyNeeded} ₪ לרכישת המתנה`);
      }
    } if(!isRegulatrionsConfirm) {
      regulationsConfirm.nextElementSibling.classList.add("input-error");
    }
    window.scrollTo(0, 0);

  };

  const goToTermsStep = () => {
    setSendChoosedPresentStep({
      blessingWrite: false,
      shipping: false,
      terms: true,
      moneyPresent: false,
      payment: false,
    });
    setStepNumber(3);
    setMainBgImage({
      main: orangeBgImage,
      noPeople: orangeBgImageNoPeople,
    });
    setOrange(true);
    setForm(true);
    setGreenMobile(true);
    setProgress(30);
    navigate('terms')
    window.scrollTo(0, 0);

  };

  const goToMoneyPresentStep = () => {
    setSendChoosedPresentStep({
      blessingWrite: false,
      shipping: false,
      moneyPresent: true,
      payment: false,
    });
    setStepNumber(3);
    setMainBgImage({
      main: shippingBg,
      noPeople: shippingBgNoPeople,
    });
    setOrange(true);
    setForm(true);
    setGreenMobile(true);
    setProgress(65);
    window.scrollTo(0, 0);

  };

  const submitMoneyPresentForm = (e) => {
    debugger;
    const form = e.target.closest(".credentials-section__content").querySelector("form");
    const presentBox = form["present-box"];
    const deliveryReceiver = form["shipping-option"];

   const sendByRegisteredMail = form["send-by-registered-mail"];
    const notificationsAgree = form["notifications-agree"];
    const regulationsConfirm = form["regulations-confirm"];
    const payWithBit = form["pay-with-bit"];
    const greetingCard = form["greeting-card"];

    const updatedResultObject = {
      ...resultObject,
      presentBox: presentBox?.checked,
    //  deliveryReceiver: deliveryReceiver.value,

  //    sendByRegisteredMail: sendByRegisteredMail.checked,
      notificationsAgree: notificationsAgree?.checked,
      regulationsConfirm: regulationsConfirm?.checked,
      payWithBit: payWithBit?.checked,
      greetingCard: greetingCard?.checked,
      "isCashGift": true,
      paymentState: "success"
      

    };
    setResultObject(updatedResultObject);
    debugger;
    createOrderByGiftMoney(updatedResultObject)
    setMoneyPresentStepInfo({
      presentBox: presentBox?.checked,
     // sendByRegisteredMail: sendByRegisteredMail.checked,
      notificationsAgree: notificationsAgree?.checked,
      regulationsConfirm: regulationsConfirm?.checked,
      payWithBit: payWithBit?.checked,
      greetingCard: greetingCard?.checked,
  
      // "wishlistItemsId": [
      //   0
      // ],
    });


  };

  console.log('Debugg_resultObject', resultObject)

  const createOrderByGiftMoney = async (sendObject) => {
    debugger;
    console.log('createOrderByGiftMoney: sendObject', sendObject)
    try {
      const response = await axios.post(`${API_DOMAIN}/api/ProductCategory/CreateOrderByGiftMoney`, sendObject)
      console.log('createOrderByGiftMoney:', response?.data)
      if (response.data.result) {
        // navigate('/successful-payment-my-bank');
        // navigate('/successful-payment-my-bank');
      //   console.log('resetMyBankOrderFlow',resetMyBankOrderFlow)
        // if (resetMyBankOrderFlow) {
        //   resetMyBankOrderFlow()
        // } else {
        //navigate('/my-bank')
        console.log("!!!!!!!!!!!!!!!!",response.data.orderId) ;

        if(additionalMoneyNeeded == 0){
          navigate('/successful-payment-referal');
      
           // navigate('/successful-payment-referal')
          }
      
           else{
          goToPayment();
          navigate('choosePaymentOption')
          window.scrollTo(0, 0);}
               //alert("hiiii")

      //  updateUserBank()
        setOrderId(response.data.orderId)

        // }

        window.scrollTo(0, 0);

      } else {
        alert('something went wrong')
      }
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(()=>{
    if (orderId) {

      const sendObj2 = {
        BuyerName: currentUser?.user?.firstName + " " + currentUser?.user?.lastName,
        BuyerEmail: currentUser?.user?.email,
        BuyerPhone: currentUser?.user?.phoneNumber,
        Price: additionalMoneyNeeded * 100,
        ProductName: choosedPresent.productName || choosedPresent.name,
        ProductId: choosedPresent.productsId,
        OrderId: orderId,
        ...(additionalMoneyNeeded > 0 && { CallbackUrl: `https://server.yyyshopping.com/api/ProductCategory/PaymedataPartly/${orderId}` })
        // WishlistItemsId: resultObject,
      };
      
    
  if(resultObject.payWithBit){
    axios
    .post(
      `${API_DOMAIN}/api/ProductCategory/PaymentBit`,
      sendObj2
    )
    .then((response) => {
        setPayment(response.data)
    })
    .catch((error) => {
      console.error(error);
    });

  }
  else{
    axios
    .post(
      `${API_DOMAIN}/api/ProductCategory/Payment`,
      sendObj2
    )
    .then((response) => {
        setPayment(response.data)
    })
    .catch((error) => {
      console.error(error);
    });
  }

   
  



goToPayment();
//navigate('payment')
navigate('choosePaymentOption');
  }
  },[orderId])
  const goToPayment = () => {
    debugger;
    setSendChoosedPresentStep({
      blessingWrite: false,
      shipping: false,
      moneyPresent: false,
      payment: true,
    });
    setStepNumber(4);
    setMainBgImage({
      main: orangeBgImage,
      noPeople: orangeBgImageNoPeople,
    });
    setOrange(true);
    setForm(true);
    setGreenMobile(true);
    setProgress(100);
    window.scrollTo(0, 0);
  };


  useEffect(() => {
    const currentStep = location.pathname.split("/").pop();
    setChoosed(true);
    console.log('currentStep', location)
    switch (currentStep) {
      case "write-blessing":
        goToShippingStep()
        break;

      case "shipping":
        goToShippingStep()
        break;

      case "money":
        goToMoneyPresentStep()
        break;


      default:
        break;
    }
    return () => {

    }
  }, [location.pathname])

  const submitPaymentMethod =()=>{
    if (orderId) {
      debugger;

      const sendObj2 = {
        BuyerName: currentUser?.user.firstName + " " + currentUser?.user.lastName,
        BuyerEmail: currentUser?.user.email,
        BuyerPhone: currentUser?.user.phoneNumber,
        Price: choosedPresent.price * 100,
        ProductName: choosedPresent.productName || choosedPresent.name,
        ProductId: choosedPresent.productsId,
        OrderId: orderId
      
      };
     
      
  
  
    if(isBit){
      axios
      .post(
        `${API_DOMAIN}/api/ProductCategory/PaymentBit`,
        sendObj2
      )
      .then((response) => {
          setPayment(response.data)
          goToPayment();
          navigate('payment')
      })
      .catch((error) => {
        console.error(error);
      });
  
    }
    else{
      axios
      .post(
        `${API_DOMAIN}/api/ProductCategory/Payment`,
        sendObj2
      )
      .then((response) => {
          setPayment(response.data)  
          goToPayment();
   navigate('payment')
      })
      .catch((error) => {
        console.error(error);
      });
    }
    }
     
    
  
  

  }


  return (
    <>
      <div className="credentials-section__steps">
        <div
          className={`credentials-section__steps-numbers numbers-steps ${isOrange ? "numbers-steps_orange" : ""
            } step-${stepNumber}`}
        >
          <ul className="numbers-steps__list">
            <li className="numbers-steps__list-item">
              <div
                className="numbers-steps__step"
                onClick={() =>
                  stepNumber !== 1 ? () => {

                    goToShippingStep()
                    navigate('shipping')

                  }
                    : null
                }
              >
                1
              </div>
            </li>
            <li className="numbers-steps__list-item">
              <div
                className="numbers-steps__step"
                onClick={() => (stepNumber > 2 ? () => {
                  goToShippingStep()
                  navigate('shipping')
                }
                  : null)}
              >
                2
              </div>
            </li>
            <li className="numbers-steps__list-item">
              <div
                className="numbers-steps__step"
                onClick={() => (stepNumber > 3 ? () => {
                  goToMoneyPresentStep()
                  navigate('money')
                }
                  : null)}
              >
                3
              </div>
            </li>
            <li className="numbers-steps__list-item">
              <div className="numbers-steps__step">4</div>
            </li>
          </ul>
        </div>
      </div>
      <div className="credentials-section__content">
        <Routes>
          <Route path="write-blessing" element={
            <>
              <button
                type="button"
                onClick={goBackToChoosePresent}
                className="credentials-section__prev-btn"
              >
                <img src={prevBtnImage} alt="arrow left black" />
              </button>
              <div className="credentials-section__form-wrapper">
                <form
                  onSubmit={(e) => {
                    submitBlessingWriteForm(e)
                    navigate('shipping')
                  }
                  }
                  action="#"
                  className="credentials-section__form form-credentials-section"
                >
                  <div className="form-credentials-section__form-body">
                    <div className="form-credentials-section__top-block">
                      <h1 className="credentials-section__title">
                        ברכה למזמינים
                      </h1>
                      <label
                        htmlFor="form-credentials-section__blessing-name"
                        className="form-credentials-section__input-wrapper input-wrapper"
                      >
                        <input
                          autoComplete="off"
                          type="text"
                          name="blessing-name"
                          id="form-credentials-section__blessing-name"
                          placeholder="כותרת הברכה"
                          className="input"
                          defaultValue={blessingWriteStepInfo.blessingTitle}
                          onInput={(e) => {
                            if (
                              e.target
                                .closest("label")
                                .classList.contains("input-error")
                            ) {
                              validateTextInput(e.target, 1);
                            }
                          }}
                        />
                      </label>
                      <label
                        htmlFor="form-credentials-section__blessing-message"
                        className="form-credentials-section__input-wrapper input-wrapper"
                      >
                        <textarea
                          autoComplete="off"
                          name="blessing-message"
                          placeholder="תוכן הברכה"
                          className="input"
                          id="form-credentials-section__blessing-message"
                          defaultValue={blessingWriteStepInfo.blessingText}
                          onInput={(e) => {
                            if (
                              e.target
                                .closest("label")
                                .classList.contains("input-error")
                            ) {
                              validateTextInput(e.target, 1);
                            }
                          }}
                        />
                      </label>
                    </div>
                    <div className="form-credentials-section__big-image-ibg">
                      <img src={choosedPresent.images} alt="" />
                    </div>
                    <div className="form-credentials-section__bottom-block">
                      <div className="form-credentials-section__buttons-container">
                        <button type="submit" className="btn">
                          {languageToggle ? languageToggle.sendPresent[language].btn1 : "אישור"}
                        </button>
                      </div>
                      <div className="credentials-section__progress">
                        <div
                          className="percent"
                          style={{ right: progress + "%" }}
                        >
                          {progress}%
                        </div>
                        <div className="bar">
                          <div className="background" />
                          <div
                            className="percent-line"
                            style={{ width: progress + "%" }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </>
          } />
          <Route path="/shipping" element={
            <>
              <button type="button"
                onClick={() => {
                  goToBlessingWriteStep()
              //    navigate('write-blessing')
                }
                }
                className="credentials-section__prev-btn">
                <img
                  src={prevBtnImage}
                  alt="arrow left black"
                />
              </button>
              <h1 className="credentials-section__title credentials-section__title_icon-top">
                <div className="mobile-icon">
                  <img src={shippingTitleIcon} alt="icon" />
                </div>
                דרכי משלוח
              </h1>
              <h2 className="credentials-section__subtitle">
                איך תרצו לשלוח את המתנה
              </h2>
              <div className="credentials-section__form-wrapper">
              <form
                  action="#"
                  onSubmit={submitShippingForm}
                  className="credentials-section__form form-credentials-section"
                >
                  <div className="long-checkbox">
                    <input
                      id="c_1"
                      className="long-checkbox__input"
                      type="checkbox"
                      defaultValue={1}
                      defaultChecked={
                        shippingStepInfo.presentBox
                          ? shippingStepInfo.presentBox
                          : false
                      }
                      name="present-box"
                    />
                    <label htmlFor="c_1" className="long-checkbox__label">
                      <span className="long-checkbox__text">
                        לארוז באריזת מתנה
                      </span>
                    </label>
                  </div>

                  <div className="long-checkbox">
                    <input
                      id="c_2"
                      className="long-checkbox__input"
                      type="radio"
                      defaultValue={2}
                      value = "collectionFromBusiness"

                      defaultChecked={
                        shippingStepInfo.collectionFromBusiness
                          ? shippingStepInfo.collectionFromBusiness
                          : false
                      }
                      name="shipping-option"
                    />
                    <label htmlFor="c_2" className="long-checkbox__label">
                      <span className="long-checkbox__text">איסוף מבית העסק</span>
                    </label>
                  </div>
                  <div className="long-checkbox desktop">
                  <div className="long-checkbox__radios">
  <p style={{flex: '0 0 19.99999999999999999999vh'}}>לשלוח בדואר רשום:</p>
  <input
    id="c_3-1"
    className="long-checkbox__input"
    type="radio"
    value="mailOwner"
    name="shipping-option"
  />
  <label htmlFor="c_3-1" className="long-checkbox__label">
    <span className="long-checkbox__text"></span>
  </label>
</div>

                  </div>
                  <div className="long-checkbox desktop">
  <div className="long-checkbox__radios">
    <p>לשלוח עם שליח:</p>

    <input
      id="c_4-1"
      className="long-checkbox__input"
      type="radio"
      value="deliveryOwner"
      name="shipping-option"
    />

    <label htmlFor="c_4-1" className="long-checkbox__label">
      <span className="long-checkbox__text"></span>
    </label>
  </div>
</div>

                  {/*     <div className="long-checkbox">
                    <input
                      id="c_3"
                      className="long-checkbox__input"
                      type="checkbox"
                      defaultValue={2}
                      defaultChecked={
                        shippingStepInfo.sendByRegisteredMail
                          ? shippingStepInfo.sendByRegisteredMail
                          : false
                      }
                      name="send-by-registered-mail"
                    />
                    <label htmlFor="c_3" className="long-checkbox__label">
                      <span className="long-checkbox__text">
                        :לשלוח בדואר רשום
                      </span>
                    </label>
                  </div>
                  <div className="long-checkbox">
                    <input
                      id="c_4"
                      className="long-checkbox__input"
                      type="checkbox"
                      defaultValue={2}
                      defaultChecked={
                        shippingStepInfo.sendWithCourier
                          ? shippingStepInfo.sendWithCourier
                          : false
                      }
                      name="send-with-courier"
                    />
                    <label htmlFor="c_4" className="long-checkbox__label">
                      <span className="long-checkbox__text">:לשלוח עם שליח</span>
                    </label>
                  </div>*/}
                  <div className="long-checkbox">
                    <input
                      id="c_5"
                      className="long-checkbox__input"
                      type="checkbox"
                      defaultValue={1}
                      name="notifications-agree"
                      defaultChecked={
                        shippingStepInfo.notificationsAgree
                          ? shippingStepInfo.notificationsAgree
                          : false
                      }
                    />
                    <label htmlFor="c_5" className="long-checkbox__label">
                      <span className="long-checkbox__text">
                        אני מאשר/ת קבלת הודעות על מבצעים והטבות
                      </span>
                    </label>
                  </div>
                  <div className="long-checkbox">
                    <input
                      id="c_6"
                      className="long-checkbox__input"
                      type="checkbox"
                      defaultValue={1}
                      defaultChecked={
                        shippingStepInfo.regulationsConfirm
                          ? shippingStepInfo.regulationsConfirm
                          : false
                      }
                      name="regulations-confirm"
                      onChange={(e) => {
                        if (
                          e.target?.checked &&
                          e.target.nextElementSibling.classList.contains(
                            "input-error"
                          )
                        ) {
                          e.target.nextElementSibling.classList.remove(
                            "input-error"
                          );
                        }
                      }}
                    />
                    <label htmlFor="c_6" className="long-checkbox__label">
                      <span className="long-checkbox__text">
                        הנני מאשר שקראתי את התקנון והבנתי אותו{" "}
                        <a
                          href="#"
                          type="button"
                          onClick={(e) => {
                            e.preventDefault();
                            goToTermsStep();
                            navigate('terms')

                          }}
                        >
                          קרא תקנון
                        </a>
                      </span>
                    </label>
                  </div>
                  <div className="long-checkbox long-checkbox_with-content">
                    <input
                      id="c_7"
                      className="long-checkbox__input"
                      type="checkbox"
                      defaultValue={1}
                      name="form[]"
                    />
                    <label htmlFor="c_7" className="long-checkbox__label">
                      <span className="long-checkbox__text">
                        נא לצרף כרטיס ברכה
                      </span>
                    </label>
                    <div className="long-checkbox__buttons">
                      <a href="#" className="btn">
                        הוספת ברכה
                      </a>
                      <a href="#" className="btn-gray">
                        לצפיה בברכה
                      </a>
                    </div>
                  </div>
                  {showErrorShipping &&
                  <span style={{color: 'red', fontSize: '1.8vh', textAlign: 'center'}}> חובה לבחור שיטת משלוח</span>
                 }
                  {!isMobile &&
                    <div className="form-credentials-section__buttons-container">
                      <button type="submit" className="btn">
                        {languageToggle ? languageToggle.sendPresent[language].btn2 : "אישור"}
                      </button>
                    </div>}
                  {isMobile && (
                    <div className="contact-us-form__btn-container btn-container_center" >
                      <button type="submit" className="btn" style={{ top: '16vh' }}>
                        {languageToggle ? languageToggle.sendPresent[language].btn2 : "אישור"}
                      </button>
                    </div>
                  )}
                </form>
              </div>
              <div className="credentials-section__decor">
                <img src={shippingDecor} alt="decor" />
              </div>
            </>
          } />

          <Route path="terms" element={
            <>
              <button type="button"
                onClick={() => {
                  goToShippingStep()
                  navigate('shipping')
                }}
                className="credentials-section__prev-btn">
                <img
                  src={prevBtnImage}
                  alt="arrow left black"
                />
              </button>
              <h1 className="credentials-section__title">תקנון</h1>
              <div className="credentials-section__form-wrapper">
                <form
                  action="#"
                  className="credentials-section__form form-credentials-section"
                >
                  <div className="credentials-section__text-scroll">
                    לורם איפסום דולור סיט אמט, קונסקטורר אדיפיסינג אלית נולום
                    ארווס סאפיאן - פוסיליס קוויס, אקווזמן גולר מונפרר סוברט לורם
                    שבצק יהול, לכנוץ בעריר גק ליץ, קולהע צופעט למרקוח איבן איף,
                    ברומץ כלרשט מיחוצים. קלאצי ליבם סולגק. בראיט ולחת צורק מונחף,
                    בגורמי מגמש. תרבנך וסתעד לכנו סתשם השמה - לתכי מורגם בורק?
                    לתיג ישבעס.
                    <br />
                    <br />
                    לורם איפסום דולור סיט אמט, קונסקטורר אדיפיסינג אלית נולום
                    ארווס סאפיאן - פוסיליס קוויס, אקווזמן גולר מונפרר סוברט לורם
                    שבצק יהול, לכנוץ בעריר גק ליץ, קולהע צופעט למרקוח איבן איף,
                    ברומץ כלרשט מיחוצים. קלאצי ליבם סולגק. בראיט ולחת צורק מונחף,
                    בגורמי מגמש. תרבנך וסתעד לכנו סתשם השמה - לתכי מורגם בורק?
                    לתיג ישבעס.
                    <br />
                    <br />
                    לורם איפסום דולור סיט אמט, קונסקטורר אדיפיסינג אלית נולום
                    ארווס סאפיאן - פוסיליס קוויס, אקווזמן גולר מונפרר סוברט לורם
                    שבצק יהול, לכנוץ בעריר גק ליץ, קולהע צופעט למרקוח איבן איף,
                    ברומץ כלרשט מיחוצים. קלאצי ליבם סולגק. בראיט ולחת צורק מונחף,
                    בגורמי מגמש. תרבנך וסתעד לכנו סתשם השמה - לתכי מורגם בורק?
                    לתיג ישבעס.
                    <br />
                    <br />
                    לורם איפסום דולור סיט אמט, קונסקטורר אדיפיסינג אלית נולום
                    ארווס סאפיאן - פוסיליס קוויס, אקווזמן גולר מונפרר סוברט לורם
                    שבצק יהול, לכנוץ בעריר גק ליץ, קולהע צופעט למרקוח איבן איף,
                    ברומץ כלרשט מיחוצים. קלאצי ליבם סולגק. בראיט ולחת צורק מונחף,
                    בגורמי מגמש. תרבנך וסתעד לכנו סתשם השמה - לתכי מורגם בורק?
                    לתיג ישבעס. לורם איפסום דולור סיט אמט, קונסקטורר אדיפיסינג
                    אלית נולום ארווס סאפיאן - פוסיליס קוויס, אקווזמן גולר מונפרר
                    סוברט לורם שבצק יהול, לכנוץ בעריר גק ליץ, קולהע צופעט למרקוח
                    איבן איף, ברומץ כלרשט מיחוצים. קלאצי ליבם סולגק. בראיט ולחת
                    צורק מונחף, בגורמי מגמש. תרבנך וסתעד לכנו סתשם השמה - לתכי
                    מורגם בורק? לתיג ישבעס.
                    <br />
                    <br />
                    לורם איפסום דולור סיט אמט, קונסקטורר אדיפיסינג אלית נולום
                    ארווס סאפיאן - פוסיליס קוויס, אקווזמן גולר מונפרר סוברט לורם
                    שבצק יהול, לכנוץ בעריר גק ליץ, קולהע צופעט למרקוח איבן איף,
                    ברומץ כלרשט מיחוצים. קלאצי ליבם סולגק. בראיט ולחת צורק מונחף,
                    בגורמי מגמש. תרבנך וסתעד לכנו סתשם השמה - לתכי מורגם בורק?
                    לתיג ישבעס.
                    <br />
                    <br />
                    לורם איפסום דולור סיט אמט, קונסקטורר אדיפיסינג אלית נולום
                    ארווס סאפיאן - פוסיליס קוויס, אקווזמן גולר מונפרר סוברט לורם
                    שבצק יהול, לכנוץ בעריר גק ליץ, קולהע צופעט למרקוח איבן איף,
                    ברומץ כלרשט מיחוצים. קלאצי ליבם סולגק. בראיט ולחת צורק מונחף,
                    בגורמי מגמש. תרבנך וסתעד לכנו סתשם השמה - לתכי מורגם בורק?
                    לתיג ישבעס.
                    <br />
                    <br />
                    לורם איפסום דולור סיט אמט, קונסקטורר אדיפיסינג אלית נולום
                    ארווס סאפיאן - פוסיליס קוויס, אקווזמן גולר מונפרר סוברט לורם
                    שבצק יהול, לכנוץ בעריר גק ליץ, קולהע צופעט למרקוח איבן איף,
                    ברומץ כלרשט מיחוצים. קלאצי ליבם סולגק. בראיט ולחת צורק מונחף,
                    בגורמי מגמש. תרבנך וסתעד לכנו סתשם השמה - לתכי מורגם בורק?
                    לתיג ישבעס. לורם איפסום דולור סיט אמט, קונסקטורר אדיפיסינג
                    אלית נולום ארווס סאפיאן - פוסיליס קוויס, אקווזמן גולר מונפרר
                    סוברט לורם שבצק יהול, לכנוץ בעריר גק ליץ, קולהע צופעט למרקוח
                    איבן איף, ברומץ כלרשט מיחוצים. קלאצי ליבם סולגק. בראיט ולחת
                    צורק מונחף, בגורמי מגמש. תרבנך וסתעד לכנו סתשם השמה - לתכי
                    מורגם בורק? לתיג ישבעס.
                    <br />
                    <br />
                    לורם איפסום דולור סיט אמט, קונסקטורר אדיפיסינג אלית נולום
                    ארווס סאפיאן - פוסיליס קוויס, אקווזמן גולר מונפרר סוברט לורם
                    שבצק יהול, לכנוץ בעריר גק ליץ, קולהע צופעט למרקוח איבן איף,
                    ברומץ כלרשט מיחוצים. קלאצי ליבם סולגק. בראיט ולחת צורק מונחף,
                    בגורמי מגמש. תרבנך וסתעד לכנו סתשם השמה - לתכי מורגם בורק?
                    לתיג ישבעס.
                    <br />
                    <br />
                    לורם איפסום דולור סיט אמט, קונסקטורר אדיפיסינג אלית נולום
                    ארווס סאפיאן - פוסיליס קוויס, אקווזמן גולר מונפרר סוברט לורם
                    שבצק יהול, לכנוץ בעריר גק ליץ, קולהע צופעט למרקוח איבן איף,
                    ברומץ כלרשט מיחוצים. קלאצי ליבם סולגק. בראיט ולחת צורק מונחף,
                    בגורמי מגמש. תרבנך וסתעד לכנו סתשם השמה - לתכי מורגם בורק?
                    לתיג ישבעס.
                    <br />
                    <br />
                    לורם איפסום דולור סיט אמט, קונסקטורר אדיפיסינג אלית נולום
                    ארווס סאפיאן - פוסיליס קוויס, אקווזמן גולר מונפרר סוברט לורם
                    שבצק יהול, לכנוץ בעריר גק ליץ, קולהע צופעט למרקוח איבן איף,
                    ברומץ כלרשט מיחוצים. קלאצי ליבם סולגק. בראיט ולחת צורק מונחף,
                    בגורמי מגמש. תרבנך וסתעד לכנו סתשם השמה - לתכי מורגם בורק?
                    לתיג ישבעס. לורם איפסום דולור סיט אמט, קונסקטורר אדיפיסינג
                    אלית נולום ארווס סאפיאן - פוסיליס קוויס, אקווזמן גולר מונפרר
                    סוברט לורם שבצק יהול, לכנוץ בעריר גק ליץ, קולהע צופעט למרקוח
                    איבן איף, ברומץ כלרשט מיחוצים. קלאצי ליבם סולגק. בראיט ולחת
                    צורק מונחף, בגורמי מגמש. תרבנך וסתעד לכנו סתשם השמה - לתכי
                    מורגם בורק? לתיג ישבעס.
                    <br />
                    <br />
                    לורם איפסום דולור סיט אמט, קונסקטורר אדיפיסינג אלית נולום
                    ארווס סאפיאן - פוסיליס קוויס, אקווזמן גולר מונפרר סוברט לורם
                    שבצק יהול, לכנוץ בעריר גק ליץ, קולהע צופעט למרקוח איבן איף,
                    ברומץ כלרשט מיחוצים. קלאצי ליבם סולגק. בראיט ולחת צורק מונחף,
                    בגורמי מגמש. תרבנך וסתעד לכנו סתשם השמה - לתכי מורגם בורק?
                    לתיג ישבעס.
                    <br />
                    <br />
                    לורם איפסום דולור סיט אמט, קונסקטורר אדיפיסינג אלית נולום
                    ארווס סאפיאן - פוסיליס קוויס, אקווזמן גולר מונפרר סוברט לורם
                    שבצק יהול, לכנוץ בעריר גק ליץ, קולהע צופעט למרקוח איבן איף,
                    ברומץ כלרשט מיחוצים. קלאצי ליבם סולגק. בראיט ולחת צורק מונחף,
                    בגורמי מגמש. תרבנך וסתעד לכנו סתשם השמה - לתכי מורגם בורק?
                    לתיג ישבעס.
                    <br />
                    <br />
                    לורם איפסום דולור סיט אמט, קונסקטורר אדיפיסינג אלית נולום
                    ארווס סאפיאן - פוסיליס קוויס, אקווזמן גולר מונפרר סוברט לורם
                    שבצק יהול, לכנוץ בעריר גק ליץ, קולהע צופעט למרקוח איבן איף,
                    ברומץ כלרשט מיחוצים. קלאצי ליבם סולגק. בראיט ולחת צורק מונחף,
                    בגורמי מגמש. תרבנך וסתעד לכנו סתשם השמה - לתכי מורגם בורק?
                    לתיג ישבעס.
                  </div>
                  <div className="form-credentials-section__buttons-container">
                    <button
                      type="button"
                      onClick={() => {
                        goToShippingStep()
                        navigate('shipping')
                      }}
                      className="btn"
                    >
                      אישור
                    </button>
                  </div>
                </form>
              </div>
            </>
          } />
          <Route path="money" element={
            <>
              <button
                type="button"
                onClick={() => {
                  goToShippingStep()
                  navigate('shipping')
                }}
                className="credentials-section__prev-btn"
              >
                <img src={prevBtnImage} alt="arrow left black" />
              </button>
              <h1 className="credentials-section__title">סיכום הזמנה</h1>
              <div className="credentials-section__content-block">
                <div className="credentials-section__table-block">
                  <div className="credentials-section__table">
                    <div className="credentials-section__table-item">
                      <p className="credentials-section__table-item-name">
                         שם הרוכש:
                      </p>
                      <p className="credentials-section__table-item-value">
                      {currentUser?.user?.firstName + " " + currentUser?.user.lastName}
                      </p>
                    </div>
                  {/*  <div className="credentials-section__table-item">
                      <p className="credentials-section__table-item-name">
                        : תאריך לידה
                      </p>
                      <p className="credentials-section__table-item-value">
                      </p>
                    </div>*/}
                    <div className="credentials-section__table-item">
                      <p className="credentials-section__table-item-name">
                         נייד:{" "}
                      </p>
                      <p className="credentials-section__table-item-value">
                        {currentUser.user.phoneNumber}
                      </p>
                    </div>
                    <div className="credentials-section__table-item">
                      <p className="credentials-section__table-item-name">
                        מייל:
                      </p>
                      <p className="credentials-section__table-item-value">
                        {currentUser.user.email}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="credentials-section__form-wrapper">
                  <form
                    action="#"
                    className="credentials-section__form form-credentials-section"
                  >
                    <div className="long-checkbox long-checkbox_with-image">
                      <input
                        id="c_0"
                        className="long-checkbox__input"
                        type="checkbox"
                        defaultValue={1}
                        defaultChecked
                        name="product"
                      />
                      <label htmlFor="c_0" className="long-checkbox__label">
                        <span className="long-checkbox__text">
                          <span className="image">
                            <img src={choosedPresent.images} alt="" />
                            <a href="#" className="loupe-btn">
                              <svg
                                id="Сгруппировать_369"
                                data-name="Сгруппировать 369"
                                xmlns="http://www.w3.org/2000/svg"
                                width="16.346"
                                height="16.765"
                                viewBox="0 0 16.346 16.765"
                              >
                                <g
                                  id="Сгруппировать_368"
                                  data-name="Сгруппировать 368"
                                >
                                  <path
                                    id="Контур_3180"
                                    data-name="Контур 3180"
                                    d="M356.417,355.722l-4.139-4.449a.686.686,0,0,0-1.021,0,.817.817,0,0,0,0,1.1l4.139,4.449a.687.687,0,0,0,1.021,0A.817.817,0,0,0,356.417,355.722Z"
                                    transform="translate(-340.283 -340.282)"
                                  />
                                  <g
                                    id="Сгруппировать_367"
                                    data-name="Сгруппировать 367"
                                  >
                                    <g
                                      id="Сгруппировать_366"
                                      data-name="Сгруппировать 366"
                                    >
                                      <path
                                        id="Контур_3179"
                                        data-name="Контур 3179"
                                        d="M6.67,0a6.67,6.67,0,1,0,6.67,6.67A6.677,6.677,0,0,0,6.67,0Zm0,12.108A5.438,5.438,0,1,1,12.108,6.67,5.445,5.445,0,0,1,6.67,12.108Z"
                                      />
                                    </g>
                                  </g>
                                </g>
                              </svg>
                            </a>
                          </span>
                          <span className="text-content">
                            <span className="text">
                            <img src={choosedPresent.image} alt="" />

                              מחיר 
                            </span>
                            <span className="price">                              
                              <span className="money-icon">₪</span>
                              {choosedPresent.price}
                            </span><br/>
                            <span className="text">
                               לתשלום{" "} 

                            </span>
                            <span className="price">                              
                              <span className="money-icon">₪</span>
                              {additionalMoneyNeeded}
                            </span><br/>

                          </span>
                        </span>
                      </label>
                    </div>
                   {/* <div className="long-checkbox">
                      <input
                        id="c_1"
                        className="long-checkbox__input"
                        type="checkbox"
                        defaultValue={1}
                        defaultChecked={moneyPresentStepInfo.presentBox}
                        name="present-box"
                      />
                      <label htmlFor="c_1" className="long-checkbox__label">
                        <span className="long-checkbox__text">
                          לארוז באריזת מתנה
                        </span>
                      </label>
                    </div>
                    <div className="long-checkbox">
                      <input
                        id="c_2"
                        className="long-checkbox__input"
                        type="checkbox"
                        defaultValue={2}
                        defaultChecked={moneyPresentStepInfo.sendByRegisteredMail}
                        name="send-by-registered-mail"
                      />
                      <label htmlFor="c_2" className="long-checkbox__label">
                        <span className="long-checkbox__text">
                          לשלוח בדואר רשום אלי
                        </span>
                      </label>
                    </div>
                    <div className="long-checkbox">
                      <input
                        id="c_3"
                        className="long-checkbox__input"
                        type="checkbox"
                        defaultValue={2}
                        defaultChecked={moneyPresentStepInfo.notificationsAgree}
                        name="notifications-agree"
                      />
                      <label htmlFor="c_3" className="long-checkbox__label">
                        <span className="long-checkbox__text">
                          אני מאשר/ת קבלת הודעות על מבצעים והטבות
                        </span>
                      </label>
                    </div>
                    <div className="long-checkbox">
                      <input
                        id="c_4"
                        className="long-checkbox__input"
                        type="checkbox"
                        defaultValue={2}
                        defaultChecked={moneyPresentStepInfo.regulationsConfirm}
                        name="regulations-confirm"
                      />
                      <label htmlFor="c_4" className="long-checkbox__label">
                        <span className="long-checkbox__text">
                          הנני מאשר שקראתי את התקנון והבנתי אותו
                          <a href="#">קרא תקנון</a>
                        </span>
                      </label>
                    </div>*/}
                    {/*
                    <div className="long-checkbox">
                      <input
                        id="c_5"
                        className="long-checkbox__input"
                        type="checkbox"
                        defaultValue={2}
                        defaultChecked={moneyPresentStepInfo.payWithBit}
                        name="pay-with-bit"
                      />
                      <label htmlFor="c_5" className="long-checkbox__label">
                        <span className="long-checkbox__text flex">
                          תשלום באמצעות
                          <div className="icon">
                            <img src={bit} alt="" />
                          </div>
                        </span>
                      </label>
                    </div>*/}
            
                  </form>
                </div>
              </div>
              <div className="form-credentials-section__buttons-container">
                <button
                  type="button"
                  onClick={submitMoneyPresentForm}
                  className="btn"
                >
                  {languageToggle ? languageToggle.sendPresent[language].btn3 : "המשך"}


                </button>
              </div>
            </>
          } />
            <Route path="choosePaymentOption" element={
            <>
              <button
                type="button"
                onClick={() => {
                  goToMoneyPresentStep()
                  navigate('money-present')
                }}
                className="credentials-section__prev-btn"
              >
                <img src={prevBtnImage} alt="arrow left black" />
              </button>
             <h1 className="credentials-section__title">
                <div className="mobile-icon">
                  <img src={creditCards} alt="icon" />
                </div>
                אמצעי תשלום
              </h1>
             <h2 className="credentials-section__subtitle">
              איך תרצו לשלם?
            </h2>

            
           <div className="credentials-section__text-scroll" style={{ overflowX: !isMobile? 'hidden': ''}}>
           <div className="long-checkbox">
  <input
    id="c_388"
    className="long-checkbox__input"
    type="radio"
    name="payment-method"
    onChange={() => setIsBit(false)}

    value="credit"
  />
  <label htmlFor="c_388" className="long-checkbox__label">
    <span className="long-checkbox__text flex">
      תשלום בכרטיס אשראי
    </span>
  </label>
</div>
<div className="long-checkbox">


  <input
    id="c_5"
    className="long-checkbox__input"
    onChange={() => setIsBit(true)}
    type="radio"
    name="payment-method"
    value="bit"
   // defaultChecked={moneyPresentStepInfo.payWithBit}
  />
  <label htmlFor="c_5" className="long-checkbox__label">
    <span className="long-checkbox__text flex">
      תשלום ב<div className="icon">
        <img src={bit} alt="" />
      </div>
    </span>
  </label>
</div>
{!isMobile &&
                <div className="form-credentials-section__buttons-container" style={{top: '19vh'}}>
                  <button
                    type="button"
                    onChange={() => setIsBit(true)}

                    onClick={submitPaymentMethod}
                    className="btn"
                  >
                    {languageToggle ? languageToggle.sendPresent[language].btn3 : "המשך"}
                  </button>
                </div>}
              {isMobile && (
                <div className="contact-us-form__btn-container btn-container_center" >
                  <button type="button" onClick={submitPaymentMethod}
                    className="btn" style={{ top: '14vh' }}>
                    {languageToggle ? languageToggle.sendPresent[language].btn3 : "המשך"}
                  </button>
                </div>
              )}
           
              </div>
         
            </>
          } />
                 <Route path="payment" element={
            <>
              <button
                type="button"
                onClick={() => {
                  goToMoneyPresentStep()
                  navigate('money-present')
                }}
                className="credentials-section__prev-btn"
              >
                <img src={prevBtnImage} alt="arrow left black" />
              </button>
             <h1 className="credentials-section__title">
                <div className="mobile-icon">
                  <img src={creditCards} alt="icon" />
                </div>
                אמצעי תשלום
              </h1>
             <h2 className="credentials-section__subtitle">
             {!isBit ? 'תשלום באמצעות כרטיס אשראי' : 'תשלום בביט'}
            </h2>

            
           <div className="credentials-section__text-scroll"  style={{overflowX:'hidden'}}>
            {!isMobile &&
            <iframe  id="myIframe"  ref={iframeRef}
            style={{
              marginTop: isBit ? '50px' : '5px',
               width: isBit ? '105%' : '118%', 
               height: isBit ? 'auto' : '720px'}}             
        title="Payme Sale"
       // src = "http://localhost:3000/thank-payme?id=1000"
      src={payment?.sale_url}

              />}
              {
                isMobile &&
                isMobile &&
                <iframe  id="myIframe"  ref={iframeRef}
                style={{
                  marginTop: resultObject.payWithBit ? '' : '5px',
                  width: resultObject.payWithBit ? '105%' : '118%', 
                   height: resultObject.payWithBit ? '320px' : '720px'}}     
                   title="Payme Sale"
          src={payment?.sale_url}
                  />

              }
              </div>
            {/* <div className="credentials-section__form-wrapper">
                <form
                  action="#"
                  onSubmit={submitPaymentForm}
                  className="credentials-section__form form-credentials-section"
                >
                  <div className="form-credentials-section__select-wrapper">
                    <span className="input-title">בחר סוג כרטיס</span>
                    <select
                      name="event-type"
                      defaultValue={"empty"}
                      className="input"
                    >
                      <option value="empty" />
                      <option value={123}>ויזה</option>
                      <option value={123}>אמריקן אקספרס</option>
                    </select>
                  </div>
                  <label
                    htmlFor="form-credentials-section__billet-number"
                    className="form-credentials-section__input-wrapper input-wrapper"
                  >
                    <span className="input-title">מספר כרטיס</span>
                    <input
                      autoComplete="off"
                      type="text"
                      name="billet-number"
                      id="form-credentials-section__billet-number"
                      className="input"
                    />
                  </label>
                  <label
                    htmlFor="form-credentials-section__id"
                    className="form-credentials-section__input-wrapper input-wrapper"
                  >
                    <span className="input-title">מספר ת.ז של בעל הכרטיס</span>
                    <input
                      autoComplete="off"
                      type="text"
                      name="id"
                      id="form-credentials-section__id"
                      className="input"
                    />
                  </label>
                  <div className="card-info">
                    <p className="form-title">תוקף</p>
                    <div className="card-info__body">
                      <label
                        htmlFor="form-credentials-section__period"
                        className="form-credentials-section__input-wrapper input-wrapper"
                      >
                        <span className="input-title">שנה</span>
                        <input
                          autoComplete="off"
                          type="number"
                          name="period"
                          id="form-credentials-section__period"
                          className="input"
                        />
                      </label>
                      <label
                        htmlFor="form-credentials-section__month"
                        className="form-credentials-section__input-wrapper input-wrapper"
                      >
                        <span className="input-title">חודש</span>
                        <input
                          autoComplete="off"
                          type="number"
                          name="month"
                          id="form-credentials-section__month"
                          className="input"
                        />
                      </label>
                      <label
                        htmlFor="form-credentials-section__id"
                        className="form-credentials-section__input-wrapper input-wrapper"
                      >
                        <span className="input-title">CVV</span>
                        <input
                          autoComplete="off"
                          type="number"
                          name="cvv"
                          id="form-credentials-section__cvv"
                          className="input"
                        />
                      </label>
                    </div>
                  </div>
                  <div className="choose-payment">
                    <p className="form-title">סוג תשלום</p>
                    <div className="choose-payment__body">
                      <div className="long-checkbox">
                        <input
                          id="payment_1"
                          className="long-checkbox__input"
                          type="radio"
                          defaultValue={1}
                          name="payment"
                        />
                        <label
                          htmlFor="payment_1"
                          className="long-checkbox__label"
                        >
                          <span className="long-checkbox__text">
                            <img src={bit} alt="" />
                          </span>
                        </label>
                      </div>
                      <div className="long-checkbox">
                        <input
                          id="payment_2"
                          className="long-checkbox__input"
                          type="radio"
                          defaultValue={2}
                          name="payment"
                        />
                        <label
                          htmlFor="payment_2"
                          className="long-checkbox__label"
                        >
                          <span className="long-checkbox__text">
                            <img src={paybox} alt="" />
                          </span>
                        </label>
                      </div>
                      <div className="long-checkbox">
                        <input
                          id="payment_3"
                          className="long-checkbox__input"
                          type="radio"
                          defaultValue={3}
                          name="payment"
                        />
                        <label
                          htmlFor="payment_3"
                          className="long-checkbox__label"
                        >
                          <span className="long-checkbox__text">
                            <img src={paypalLong} alt="" />
                          </span>
                        </label>
                      </div>
                      <p className="form-credentials-section__text-under-checks" style={{ marginBottom: isMobile ? '3vh' : '' }}>
                        {" "}
                        אנחנו נשתמש בפרטים האישיים כדי לעבד את ההזמנה, להציע לך
                        תמיכה בתהליך באתר זה וכדי .לבצע פעולות נוספות כפי שמפורט
                        במדיניות פרטיות
                      </p>
                    </div>
                  </div>
                  {!isMobile &&
                    <div className="form-credentials-section__buttons-container">
                      <button type="submit" className="btn">
                        {languageToggle ? languageToggle.sendPresent[language].btn4 : "המשך"}
                      </button>
                    </div>}
                  {isMobile && (
                    <div className="not-scroll">
                      <div className="contact-us-form__btn-container btn-container_center" >
                        <button type="submit" className="btn" style={{ bottom: '8.5vh', position: 'fixed', right: '5vh' }}>
                          {languageToggle ? languageToggle.sendPresent[language].btn4 : "המשך"}
                        </button>
                      </div>
                    </div>
                  )}

                </form>
              </div>*/}
            </>
          } />
        </Routes>

        {!sendChoosedPresentStep.blessingWrite && (
          <div className="credentials-section__progress">
            <div className="percent" style={{ right: progress + "%" }}>
              {progress}%
            </div>
            <div className="bar">
              <div className="background" />
              <div className="percent-line" style={{ width: progress + "%" }} />
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default MyBankCreateOrder;
